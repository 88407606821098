import React from "react"
import RichText from "@madebyconnor/rich-text-to-jsx"
import { graphql } from "gatsby"

export const data = graphql`
  query getDatenschutz {
    datenschutz: contentfulDatenschutzDatenschutzRichTextNode {
      json
    }
  }
`

const DatenschutzPage = ({ data }) => {
  const {
    datenschutz: { json },
  } = data
  return (
    <div>
      <RichText richText={json} />
    </div>
  )
}

export default DatenschutzPage
